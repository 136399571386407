const firebaseConfig = {
  apiKey: "AIzaSyAwmCeNNWkLkBN_kaJX2kdVU1k58jspPq4",
  authDomain: "icsa-facturas.firebaseapp.com",
  databaseURL: "https://icsa-facturas.firebaseio.com",
  projectId: "icsa-facturas",
  storageBucket: "icsa-facturas.appspot.com",
  messagingSenderId: "48416640027",
  appId: "1:48416640027:web:f353285fd9b7170a9dfcbe",
  measurementId: "G-54XG7CK8QD"
};

  export default firebaseConfig;