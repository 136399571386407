import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { Toolbar, Typography, Container } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase/app';
import 'firebase/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'sticky',
        top: 0,
        zIndex: 5
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

export default function Header(props) {
    const classes = useStyles();

    return (
        <Container className={classes.root} disableGutters={true}>
            <AppBar position="static" color="secondary">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="primary" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" className={classes.title} color="primary">
                        ICSA Facturas
                </Typography>
                    <Button color="primary" onClick={() => firebase.auth().signOut()}>Salir</Button>
                </Toolbar>
            </AppBar>
        </Container>
    );
}