import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { CardHeader } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    tablero: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10
    },
    carta: {
        width: 300,
        minHeight: 200,
        margin: 20
    },
    header: {
        backgroundColor: '#010326'
    },
    tituloCarta: {
        textAlign: 'left',
        color: '#fa6320',
    },
    contenido: {
        fontSize: 18
    }

}));

export default function TableroMovil(props) {
    const classes = useStyles();

    return (
        <Container className={classes.tablero}>
            <Card className={classes.carta}>
                <CardHeader title='Factura nueva' classes={{ title: classes.tituloCarta, root: classes.header }} />
                <CardContent onClick={() => window.open('/facturaMovil','_self')}>
                    <Typography variant='h5'>Solicitar la preparación de una factura.</Typography>
                </CardContent>
            </Card>
            <Card className={classes.carta}>
                <CardHeader title='Registro de facturas' classes={{ title: classes.tituloCarta, root: classes.header }} />
                <CardContent onClick={() => window.open('/registroFacturasMovil','_self')}>
                    <Typography variant='h5' >Ver el registro de facturas anteriores.</Typography>
                </CardContent>
            </Card>
        </Container>
    );
}