import React, { useState, useEffect } from 'react';
import './App.css';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './util/firebaseConfig';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import { Container } from '@material-ui/core';
import Header from "./Componentes/Header";
import HeaderMovil from "./Componentes/HeaderMovil";
import SignIn from './Componentes/SignIn';
import Tablero from './Componentes/Tablero';
import TableroMovil from './Componentes/TableroMovil';
import Factura from './Componentes/Factura';
import FacturaMovil from './Componentes/FacturaMovil';
import RegistroFacturas from './Componentes/RegistroFacturas';
import RegistroFacturasMovil from './Componentes/RegistroFacturasMovil';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#fa6320' },
    secondary: { main: "#010326" },
  },
  status: {
    danger: "#282c34",
  },
});

firebase.initializeApp(firebaseConfig);

export default function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log('User is signed in.');
        setUser(user);
      } else {
        console.log('No user is signed in.');
        setUser(null);
      }
    });
  });

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    if (window.screen.width <= 720) {
      setMobile(true);
      setLoading(false);
    } else {
      setMobile(false);
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <CircularProgress color="primary" />
    );
  }

  if (!user) {
    return (
      <ThemeProvider theme={theme}>
        <Container className="App">
          <SignIn />
        </Container>
      </ThemeProvider>
    );
  }

  if (user) {
    if (!mobile) {
      return (
        <ThemeProvider theme={theme}>
          <Container className="App" maxWidth={false}>
            <Router>
              <Header />
              <Switch>
                <Route path="/registroFacturas" render={() => <RegistroFacturas user={user} />} />
                <Route path="/factura" render={() => <Factura user={user} />} />
                <Route path="/" render={() => <Tablero user={user} />} />
              </Switch>
            </Router>
          </Container>
        </ThemeProvider>
      );
    }
    if (mobile) {
      return (
        <ThemeProvider theme={theme}>
          <Container className="App" maxWidth={false} disableGutters={true}>
            <Router>
              <HeaderMovil />
              <Switch>
                <Route path="/registroFacturasMovil" render={() => <RegistroFacturasMovil user={user} />} />
                <Route path="/facturaMovil" render={() => <FacturaMovil user={user} />} />
                <Route path="/" render={() => <TableroMovil user={user} />} />
              </Switch>
            </Router>
          </Container>
        </ThemeProvider>
      );
    }
  }
}