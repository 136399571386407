import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 20
    },
    titulo: {
        margin: 20,
        width: '90%',
        textAlign: 'left'
    },
    factura: {
        width: '90%'
    },
    resumen: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center'
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    detalles: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'center',
        flexGrow: 2
    },
    opciones: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'space-between'
    },
    check: {
        alignSelf: 'center',
        height: 'fit-content',
        width: 'fit-content'
    },
    delete: {
        alignSelf: 'center',
        height: 'fit-content',
        width: 'fit-content'
    }
}));

export default function RegistroFacturasMovil(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [borrar, setBorrar] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [load, setLoad] = useState(false);
    const [solicitudes, setSolicitudes] = useState([]);
    //codigo sacado de los faqs de React
    useEffect(() => {
        let ignore = false;

        async function getSolicitudes() {
            const response = await fetch('https://us-central1-centered-kiln-258017.cloudfunctions.net/getSolicitudesFacturas');
            const jsonResponse = await response.json();
            if (!ignore) setSolicitudes(jsonResponse);
        }
        getSolicitudes();
        return () => {
            ignore = true;
            setLoad(true);
        }
    }, [props.user.email, load]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    async function handleCheck (index) {
        let bool = solicitudes[index].hecha === 1 ? 0 : 1;
        let id = solicitudes[index].id;

        let params = {
            id: id,
            hecha: bool
        };
        console.log(params);
        const response = await fetch('https://us-central1-centered-kiln-258017.cloudfunctions.net/cambiarEstadoSolicitudFactura', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        });
        console.log(response);
        if (response.ok) {
            setLoad(false);
        }
    }

    function confirmarBorrarSolicitud(id) {
        setBorrar(id);
        setOpen(true);
    }

    async function borrarSolicitud() {
        let params = { id: borrar };
        const response = await fetch('https://us-central1-centered-kiln-258017.cloudfunctions.net/borrarFactura', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        });
        console.log(response);
        if (response.status === 200) {
            setOpen(false);
            setLoad(false);
        }
    }

    return (
        <Container className={classes.root}>
            <Typography variant="h6" className={classes.titulo}>Solicitudes registradas</Typography>
            {solicitudes.length > 0 ? solicitudes.map((solicitud, index) => {
                let fecha = new Date(solicitud.fecha);
                return (
                    <ExpansionPanel className={classes.factura}
                        key={`panel${index}`}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.resumen }}>
                            <Typography variant="body1">
                                Factura a <b>{solicitud.receptor}</b>.
                            </Typography>
                            <Typography variant="body1" style={{ alignSelf: 'center' }}>
                                <b>{`${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`}</b>
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Container className={classes.detalles}>
                                <Typography align='left' variant="body1"><b>Solicitada por:</b> {solicitud.nombre}</Typography>
                                <Typography align='left' variant="body1"><b>Proyecto:</b> {solicitud.proyecto}</Typography>
                                <Typography align='left' variant="body1"><b>Moneda:</b> {solicitud.moneda}</Typography>
                                <Typography align='left' variant="body1"><b>Monto:</b> {solicitud.monto}</Typography>
                                <Typography align='left' variant="body1"><b>I.V.A.:</b> {solicitud.iva}</Typography>
                                <Typography align='left' variant="body1"><b>Total:</b> {solicitud.total}</Typography>
                                <Typography align='left' variant="body1"><b>Detalle:</b> {solicitud.detalle}</Typography>
                                <Container className={classes.opciones}>
                                    <IconButton onClick={() => confirmarBorrarSolicitud(solicitud.id)} aria-label="delete" className={classes.boton}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <Checkbox color="primary" className={classes.check} checked={solicitud.hecha === 1 ? true : false} onClick={() => handleCheck(index)} />
                                </Container>
                            </Container>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            }) : <CircularProgress color="primary" />}
            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        ¿Desea borrar esta factura?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        No
                    </Button>
                    <Button onClick={borrarSolicitud} color="primary">
                        Sí
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}