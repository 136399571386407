import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 20
    },
    factura: {
        width: '90%',
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    titulo: {
        textAlign: 'left',
        paddingBottom: 5
    },
    input: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        margin: 10
    },
    receptor: {
        width: '90%',
        marginLeft: 20,
        paddingBottom: 20
    },
    proyecto: {
        width: '90%',
        marginLeft: 20,
        paddingBottom: 20
    },
    moneda: {
        width: '80%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 0 15px 25px',
        paddingRight: 40,
        paddingBottom: 5
    },
    check: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        margin: 0
    },
    iva: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 0 15px 25px',
        paddingRight: 40,
        paddingBottom: 5
    },
    total: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 0 15px 25px',
        paddingRight: 40,
        paddingBottom: 5
    },
    monto: {
        width: '90%',
        marginLeft: 20,
        paddingBottom: 20
    },
    detalle: {
        width: '90%',
        marginLeft: 20,
        paddingBottom: 10
    },
    boton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 10
    }
}));

export default function Factura(props) {
    const classes = useStyles();
    const [receptor, setReceptor] = useState('');
    const [detalle, setDetalle] = useState('');
    const [monto, setMonto] = useState(0);
    const [iva, setIva] = useState(0);
    const [conIVA, setConIVA] = useState(true);
    const [total, setTotal] = useState(0);
    const [proyecto, setProyecto] = useState('');
    const [moneda, setMoneda] = useState('Dólares');
    const [open, setOpen] = useState(false);
    const [enviado, setEnviado] = useState(false);

    async function enviarSolicitud() {
        if (detalle === '' || receptor === '' || proyecto === '' || monto === 0 || isNaN(monto)) {
            setOpen(true);
            return;
        }
        let params = {
            nombre: props.user.email,
            receptor: receptor,
            proyecto: proyecto,
            moneda: moneda,
            monto: monto,
            iva: iva,
            total: total,
            detalle: detalle,
            fecha: Date.now(),
            hecha: 0
        }
        const response = await fetch('https://us-central1-centered-kiln-258017.cloudfunctions.net/regsitrarFactura', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        });
        console.log(response);

        fetch('https://us-central1-centered-kiln-258017.cloudfunctions.net/enviarCorreoSolicitudFactura', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        });

        setEnviado(true);
    }

    function aceptarEnvio() {
        setEnviado(false);
        window.open('/', '_self');
    }

    function cobro(e) {
        setMonto(parseInt(e.target.value));
        setIva(parseInt(e.target.value) * 0.13);
        setTotal(parseInt(e.target.value) + parseInt(e.target.value) * 0.13);
    }

    function handleCheck () {
        if (conIVA) {
            setIva(0);
            setTotal(monto)
        } else {
            setIva(monto * 0.13);
            setTotal(monto + monto * 0.13);
        }
        setConIVA(!conIVA);
    }

    return (
        <Container className={classes.root}>
            <Paper elevation={2} className={classes.factura}>
                <Typography variant='h6' className={classes.titulo}>Solicitud de factura</Typography>
                <TextField onChange={event => setReceptor(event.target.value)} className={classes.receptor} label="Receptor" variant="outlined" />
                <TextField onChange={event => setProyecto(event.target.value)} className={classes.proyecto} label="Proyecto" variant="outlined" />
                <Container className={classes.moneda} disableGutters={true}>
                    <Typography variant='body1' align='left' style={{ fontWeight: 600 }}><b>Moneda:</b></Typography>
                    <Select value={moneda} onChange={(e) => setMoneda(e.target.value)}>
                        <MenuItem value={'Dólares'}>Dólares</MenuItem>
                        <MenuItem value={'Colones'}>Colones</MenuItem>
                    </Select>
                </Container>
                <TextField onChange={event => cobro(event)} className={classes.monto} label="Monto" variant="outlined" />
                <Container className={classes.iva} disableGutters={true}>
                    <Container className={classes.check} disableGutters={true}>
                        <Checkbox size='small' color="primary" className={classes.check} checked={conIVA ? true : false} onClick={() => handleCheck()}/>
                        <Typography style={{ fontWeight: 600 }} variant='body1'>I.V.A.:</Typography>
                    </Container>
                    <Typography variant='body1'>{!isNaN(iva) ? (iva).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</Typography>
                </Container>
                <Container className={classes.total} disableGutters={true}>
                    <Container className={classes.check} disableGutters={true}>
                        <Box style={{width: 38, heigth: 38}} />
                        <Typography style={{ fontWeight: 600 }} variant='body1'>Total:</Typography>
                    </Container>
                    <Typography variant='body1'>{!isNaN(total) ? (total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</Typography>
                </Container>
                <TextField onChange={event => setDetalle(event.target.value)} className={classes.detalle} label="Detalle*" multiline rows='3' variant="outlined" />
                <Container className={classes.boton}>
                    <Button color='primary' variant="contained" style={{ width: '50%' }} onClick={enviarSolicitud}>
                        Enviar
                    </Button>
                </Container>
            </Paper >

            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Por favor complete todos los campos de la factura.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={enviado}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Solicitud enviada.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={aceptarEnvio} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
}
