import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    contenedor: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 50,
        paddingBottom: 50
    },
    factura: {
        width: '50%',
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'flex-start'
    },
    titulo: {
        textAlign: 'left',
        paddingBottom: 5
    },
    input: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 10
    },
    linea: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 0
    },
    receptor: {
        minWidth: '60%',
        marginLeft: 20
    },
    proyecto: {
        minWidth: '60%',
        marginLeft: 20
    },
    iva: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        height: 90
    },
    total: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        height: 90
    },
    monto: {
        margin: 0,
        minWidth: '30%',
        paddingRight: 10
    },
    detalle: {
        width: '90%',
    },
    boton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20,
        marginBottom: 10
    }
}));

export default function Factura(props) {
    const classes = useStyles();
    const [receptor, setReceptor] = useState('');
    const [detalle, setDetalle] = useState('');
    const [monto, setMonto] = useState(0);
    const [iva, setIva] = useState(0);
    const [conIVA, setConIVA] = useState(true);
    const [total, setTotal] = useState(0);
    const [proyecto, setProyecto] = useState('');
    const [moneda, setMoneda] = useState('Dólares');
    const [open, setOpen] = useState(false);
    const [enviado, setEnviado] = useState(false);

    async function enviarSolicitud() {
        if (detalle === '') {
            setOpen(true);
            return;
        }
        let params = {
            nombre: props.user.email,
            receptor: receptor,
            proyecto: proyecto,
            moneda: moneda,
            monto: (monto).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            iva: (iva).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            total: (total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            detalle: detalle,
            fecha: Date.now(),
            hecha: 0
        }
        console.log(params);
        const response = await fetch('https://icsa-server.herokuapp.com/facturas', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        });
        console.log(response);

        fetch('https://icsa-server.herokuapp.com/facturas/enviarCorreoSolicitudFactura', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json"
            }
        });

        setEnviado(true);
    }

    function aceptarEnvio() {
        setEnviado(false);
        //window.open('/', '_self');
    }

    function cobro(e) {
        setMonto(parseInt(e.target.value));
        if (conIVA) {
            setIva(parseInt(e.target.value) * 0.13);
            setTotal(parseInt(e.target.value) + parseInt(e.target.value) * 0.13);
        } else {
            setTotal(parseInt(e.target.value))
        }
    }

    function handleCheck () {
        if (conIVA) {
            setIva(0);
            setTotal(monto)
        } else {
            setIva(monto * 0.13);
            setTotal(monto + monto * 0.13);
        }
        setConIVA(!conIVA);
    }

    return (
        <Container className={classes.contenedor}>
            <Paper elevation={2} className={classes.factura}>
                <Typography variant='h4' className={classes.titulo}>Solicitud de factura</Typography>
                <Container className={classes.input}>
                    <Container className={classes.linea}>
                        <Typography variant='h5' style={{ minWidth: 120 }} align='left'>Facturar a: </Typography>
                        <TextField onChange={event => setReceptor(event.target.value)} className={classes.receptor} label="Receptor" variant="outlined" />
                    </Container>
                </Container>
                <Container className={classes.input}>
                    <Container className={classes.linea}>
                        <Typography variant='h5' style={{ minWidth: 120 }} align='left'>Proyecto: </Typography>
                        <TextField onChange={event => setProyecto(event.target.value)} className={classes.proyecto} label="Proyecto" variant="outlined" />
                    </Container>
                </Container>
                <Container className={classes.input}>
                    <Container className={classes.linea}>
                        <Typography variant='h5' style={{ minWidth: 120 }} align='left'>Moneda: </Typography>
                        <Select value={moneda} onChange={(e) => setMoneda(e.target.value)}>
                            <MenuItem value={'Dólares'}>Dólares</MenuItem>
                            <MenuItem value={'Colones'}>Colones</MenuItem>
                        </Select>
                    </Container>
                </Container>
                <Container className={classes.input}>
                    <Container className={classes.linea}>
                        <TextField id='monto' onChange={event => cobro(event)} className={classes.monto} label="Monto" variant="outlined" />
                        <Container className={classes.iva}>
                            <Typography style={{ fontWeight: 600 }} variant='h6'>
                                <Checkbox size='small' color="primary" className={classes.check} checked={conIVA ? true : false} onClick={() => handleCheck()}/>
                                I.V.A.:
                            </Typography>
                            <Typography variant='h6'>{iva !== 0 ? (iva).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</Typography>
                        </Container>
                        <Container className={classes.total}>
                            <Typography style={{ fontWeight: 600 }} variant='h6'>Total:</Typography>
                            <Typography variant='h6'>{total !== 0 ? (total).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}</Typography>
                        </Container>
                    </Container>
                </Container>
                <Container className={classes.input}>
                    <TextField onChange={event => setDetalle(event.target.value)} className={classes.detalle} label="Detalle*" multiline rows='4' variant="outlined" />
                </Container>
                <Container className={classes.boton}>
                    <Button color='primary' variant="contained" style={{ marginRight: 60 }} onClick={enviarSolicitud}>
                        Enviar
                    </Button>
                </Container>
            </Paper >

            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Por favor complete el detalle de la factura.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={enviado}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Solicitud enviada
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={aceptarEnvio} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container >
    );
}