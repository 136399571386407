import React from 'react';
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { Toolbar, Typography } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase/app';
import 'firebase/auth';

const styles = {
    header: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#010326',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15
    },
    menuTitulo: {
        display: 'flex',
        flexDirection: 'row'
    },
    hamburger: {
        margin: 'auto',
        cursor: 'pointer',
        fontSize: 60
        
    },
    titulo: {
        fontWeight: 500,
        paddingLeft: 20
    },
    paper: {
        backgroundColor: "#f2f2f2"
    },
    primary: {
        color: '#fa6320',
        fontSize: 30
    },
    signOut: {
        margin: 'auto 30px'
    }
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
        this.abrirMenu = this.abrirMenu.bind(this);
    }

    abrirMenu() {
        let open = this.state.menu;
        this.setState({ menu: !open });
    }

    render() {
        const { classes } = this.props;

        return (
            <AppBar className={classes.header} position='static'>
                <Toolbar className={classes.menuTitulo}>
                    <MenuIcon className={classes.hamburger} color="primary" onClick={this.abrirMenu}></MenuIcon>
                    <Link to="/" style={{ textDecoration: 'unset' }}><Typography variant='h3' style={styles.titulo} color="primary">FACTURAS</Typography></Link>
                </Toolbar>
                {/*<Drawer classes={{ paper: classes.paper }} anchor='left' open={this.state.menu} onClose={this.abrirMenu}>
                    <List component="nav" aria-label="main mailbox folders" style={{ width: 400 }}>
                        <ListItem button onClick={this.abrirMenu}>
                            <ListItemIcon>
                                <ListAltSharpIcon color="primary" style={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} primary="Planilla Construcción" />
                        </ListItem>
                        <ListItem button onClick={this.abrirMenu} divider={true}>
                            <ListItemIcon>
                                <ListAltSharpIcon color="primary" style={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} primary="Planilla Administrativa" />
                        </ListItem>
                        <ListItem button onClick={this.abrirMenu}>
                            <ListItemIcon>
                                <CloudIcon color="primary" style={{ fontSize: 40 }} />
                            </ListItemIcon>
                            <ListItemText classes={{ primary: classes.primary }} primary="Planillas Anteriores" />
                        </ListItem>
                    </List>
                </Drawer>*/}
                <Button className={classes.signOut} onClick={() => this.props.signOut()} variant="contained" color="primary">Cerrar sesión</Button>
            </AppBar>
        );
    }
}

export default withStyles(styles)(withRouter(Header));